// Custom CSS for b-form-datepicker

.b-form-datepicker.form-control {
  // Remove background validation images from main wrapper
  background-image: none;

  > .btn {
    line-height: 1;
    font-size: inherit;
    box-shadow: none !important;

    > div {
      transition: transform 0.15s;
    }

    &:disabled {
      pointer-events: none;
    }

    &:hover:not(:disabled),
    &:focus:not(:disabled) {
      > div {
        transform: scale(1.125);
      }
    }
  }

  &.is-valid > .btn {
    color: $form-feedback-valid-color;
  }

  &.is-invalid > .btn {
    color: $form-feedback-invalid-color;
  }

  &.focus {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }

    &.is-valid {
      border-color: $form-feedback-valid-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-valid-color, 0.25);
    }

    &.is-invalid {
      border-color: $form-feedback-invalid-color;
      box-shadow: 0 0 0 $input-focus-width rgba($form-feedback-invalid-color, 0.25);
    }
  }

  > label {
    @if $enable-pointer-cursor-for-buttons {
      cursor: pointer;
    }
  }

  // Disabled and read-only styling
  &[aria-disabled="true"],
  &[aria-readonly="true"] {
    background-color: $input-disabled-bg;
    opacity: 1;
  }

  &[aria-disabled="true"] {
    pointer-events: none;

    > label {
      cursor: default;
    }
  }
}
